import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import $ from 'jquery';
import Drift from 'drift-zoom'
export default class extends Controller {
static targets = ["pane", "image"]

connect() {

  const pane = document.querySelector('#demo-contain');
  console.log(document.querySelectorAll('#demo-contain'));
  document.querySelectorAll('#demo-contain').forEach(div => {
      new Drift(div.firstElementChild, {
          paneContainer: div,
          inlinePane: false
      });
  });



  }

}
