import { Controller } from "stimulus"
import $ from 'jquery';
import Select2 from "select2"

export default class extends Controller {
  static targets = [ "select" ]

  connect() {
    this.select2mount()
    document.addEventListener("turbolinks:before-cache", () => {
      this.select2unmount()
    }, { once: true })
  }

  select2mount() {
    const placeholder = $(this.selectTarget).data('placeholder')
    const dropdownParent = $(this.selectTarget).data('parent')
    const minimum = $(this.selectTarget).data('minimum')
    var search = $(this.selectTarget).data('search')
    if (search == "yes")
    {
      $(this.selectTarget).select2({
        placeholder: placeholder,
        minimumResultsForSearch: minimum,
        dropdownParent: dropdownParent,
          ajax: {
            type: 'GET',
            url: `/oeuvres_client_search`,
            dataType: 'json',
            delay: 250,
            data: function (params) {
              return {
                q: params.term, // search term
              };
            },
            processResults: function (data) {
              return {
                results: data
              };
            },
            cache: true
          },
        });
    }
    else
    {
      $(this.selectTarget).select2({
        placeholder: placeholder,
        minimumResultsForSearch: minimum,
        dropdownParent: dropdownParent

    });

  }
  $(this.selectTarget).on('select2:select', function () {
    let event = new Event('change', { bubbles: true }) // fire a native event
    this.dispatchEvent(event);
  });
  }



  select2unmount() {
  $(this.selectTarget).select2('destroy');
  }


}
