import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import Sortable from "sortablejs"

export default class extends Controller {
static targets = [ 'position', 'artiste_id', 'expo', 'id']
connect() {
    this.sortable = Sortable.create(this.element, {
    group:"list",
    onEnd: this.end.bind(this)
  })
}

end(event) {
  let template = event.item.dataset.id
  let position = event.to.dataset.position
  let artiste_id = event.to.dataset.artiste_id
  Rails.ajax({
    url:`/artistes/update_block_template/${artiste_id}?position=${position}&template=${template}`,
    type:'POST'
  })
  }
}
