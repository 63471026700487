import { Controller } from 'stimulus';
import Carousel from "stimulus-carousel"
import AOS from 'aos'
export default class extends Controller {

connect() {
     new Swiper('.swiper-container-cart', {
       loop:true,
       slidesPerView: 4,
       spaceBetween: 10,
       observer:true,
        autoplay:{
         delay: 4000,
         disableOnInteraction: false
          },
        speed: 1500,
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: true,
        },

  });

  new Swiper('.swiper-container-cart-mobile', {
    loop:true,
    slidesPerView: 1,
    spaceBetween: 10,
    observer:true,
    centeredSlides:true,
     autoplay:{
      delay: 4000,
      disableOnInteraction: false
       },
     speed: 1500,
     scrollbar: {
       el: ".swiper-scrollbar",
       hide: true,
     },

});

  }

}
