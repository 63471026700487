// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = [ 'artiste','email', 'container' ]
connect() {
  }
load() {
    this.id = event.target.dataset.id;
    this.email= this.data.get("email");
    this.fetchContacts();
  }

fetchContacts() {
    fetch(this.url)
      .then(response => response.text())
      .then(html => {
        this.containerTarget.innerHTML = html;
      });
  }
get artiste() {
    return this.artisteTargets;
  }
get url() {
    return `${this.data.get('url')}?artiste_id=${this.id}&email_id=${this.email}`
  }
}
