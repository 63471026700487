import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content"];
  connect(){

    }

  toggle() {
    if ($(location).attr('href').indexOf("/fr/") >= 0)
    {
    if ($("#more_info").hasClass("open"))
    {
      $("#more_info").removeClass("open");
      $("#more_info").addClass("close1");
      $("#more_info").html("LIRE MOINS <i class='las la-angle-left' style='font-size:16px;vertical-align: top'></i>");
    }
    else
    {
      $("#more_info").removeClass("close1");
      $("#more_info").addClass("open");
      $("#more_info").html("LIRE PLUS <i class='las la-angle-right' style='font-size:16px;vertical-align: top'></i>");
    }

    if ($("#more_info_mobile").hasClass("open"))
    {
      $("#more_info_mobile").removeClass("open");
      $("#more_info_mobile").addClass("close1");
      $("#more_info_mobile").html("LIRE MOINS <i class='las la-angle-up' style='font-size:16px;vertical-align: top'></i>");
    }
    else
    {
      $("#more_info_mobile").removeClass("close1");
      $("#more_info_mobile").addClass("open");
      $("#more_info_mobile").html("LIRE PLUS <i class='las la-angle-down' style='font-size:16px;vertical-align: top'></i>");
    }
  }
  else
  {
    if ($("#more_info").hasClass("open"))
    {
      $("#more_info").removeClass("open");
      $("#more_info").addClass("close1");
      $("#more_info").html("READ LESS <i class='las la-angle-left' style='font-size:16px;vertical-align: top'></i>");
    }
    else
    {
      $("#more_info").removeClass("close1");
      $("#more_info").addClass("open");
      $("#more_info").html("READ MORE <i class='las la-angle-right' style='font-size:16px;vertical-align: top'></i>");
    }

    if ($("#more_info_mobile").hasClass("open"))
    {
      $("#more_info_mobile").removeClass("open");
      $("#more_info_mobile").addClass("close1");
      $("#more_info_mobile").html("READ LESS <i class='las la-angle-up' style='font-size:16px;vertical-align: top'></i>");
    }
    else
    {
      $("#more_info_mobile").removeClass("close1");
      $("#more_info_mobile").addClass("open");
      $("#more_info_mobile").html("READ MORE <i class='las la-angle-down' style='font-size:16px;vertical-align: top'></i>");
    }
  }
    this.content= this.contentTarget
    this.nomdeclass = "." + this.content.className
    $(`${this.nomdeclass}`).slideToggle(500);

  }
}
