import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "oeuvre"]

  update() {
    this.oeuvre = this.oeuvreTarget.innerText;
   clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      $(`#${this.oeuvre}`).submit()
    }, 500)
  }
}
