
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import Rails from "@rails/ujs"
import Sortable from "stimulus-sortable"
import ReadMore from "stimulus-read-more"
import Carousel from "stimulus-carousel"
import * as ActiveStorage from "@rails/activestorage"


const application = Application.start()
const context = require.context("controllers", true, /_controller\.(js|ts)$/)
application.load(definitionsFromContext(context))


application.register("read-more", ReadMore)
application.register("carousel", Carousel)
