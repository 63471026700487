
$("#nav-icon4").click(function() {
  if ($("#nav-icon4").hasClass('open'))
  {
    $("#nav-icon4").toggleClass('open', 1500);
    $('.side_menu').css({ 'right':'-360px' });
    if ($("#nav-icon4").length)
    {
      $('#myCartPage').css({ 'right':'-500px' });

    }
    var count = $('.list_item').length;
    $('.list_item').css({
      'opacity':'0',
      'margin-right':'-80px'
    });

  }
  else
  {
    $(this).removeClass('closed').addClass('opened');
    $('.side_menu').css({ 'right':'0px' });
    if ($("#nav-icon4").length)
    {
      $('#myCartPage').css({ 'right':'-500px' });

    }
    $("#nav-icon4").toggleClass('open');
    var count = $('.list_item').length;
    $('.list_load').slideDown( (count*.3)*100 );
    $('.list_item').each(function(i){
      var thisLI = $(this);
      timeOut = 150*i;
      setTimeout(function(){
        thisLI.css({
          'opacity':'1',
          'margin-right':'0'
        });
      },70*i);
    });
  }

});

$("#cart_link").click(function() {
  if ($("#nav-icon4").hasClass('open'))
  {
    $("#nav-icon4").toggleClass('open', 1500);
    $('.side_menu').css({ 'right':'-360px' });
    if ($("#nav-icon4").length)
    {
      $('#myCartPage').css({ 'right':'-500px' });

    }
    var count = $('.list_item').length;
    $('.list_item').css({
      'opacity':'0',
      'margin-right':'-80px'
    });

  }
});




$("#nav_section_artiste").click(function() {
  if ($("#nav_section_artiste").hasClass('open'))
  {
    $("#nav_section_artiste").toggleClass('open', 1500);
    $('.side_menu').css({ 'right':'-360px' });
    if ($("#nav-icon4").length)
    {
      $('#myCartPage').css({ 'right':'-500px' });

    }
    var count = $('.list_item').length;
    $('.list_item').css({
      'opacity':'0',
      'margin-right':'-80px'
    });

  }
  else
  {
    $(this).removeClass('closed').addClass('opened');
    $('.side_menu').css({ 'right':'0px' });
    $("#nav_section_artiste").toggleClass('open');
    if ($("#nav-icon4").length)
    {
      $('#myCartPage').css({ 'right':'-500px' });

    }
    var count = $('.list_item').length;
    $('.list_load').slideDown( (count*.3)*100 );
    $('.list_item').each(function(i){
      var thisLI = $(this);
      timeOut = 150*i;
      setTimeout(function(){
        thisLI.css({
          'opacity':'1',
          'margin-right':'0'
        });
      },70*i);
    });
  }

  });
