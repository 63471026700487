import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "results", "form" ]

 connect() {


    if ($('#oeuvres_localisation_search').length)
    {
      $('#oeuvres_localisation_search').on("select2:select", function() {
          Rails.fire(document.querySelector('#form_seach'), 'submit');
       });
     }

       if ($('#expo_artiste').length)
       {
         $('#expo_artiste').on("select2:select", function() {
             Rails.fire(document.querySelector('#form_seach'), 'submit');
          });
       }

       if ($('#expo_years').length)
       {
         $('#expo_years').on("select2:select", function() {
             Rails.fire(document.querySelector('#form_seach'), 'submit');
          });
       }



  

       if ($('#product_artiste').length)
       {
         $('#product_artiste').on("select2:select", function() {
             Rails.fire(document.querySelector('#form_seach'), 'submit');
          });
       }





   }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(this.formTarget, 'submit')
    }, 300)
  }
}
