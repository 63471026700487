import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = [ 'image_id', 'artiste_id', 'block', 'id' ]
connect() {
    this.sortable = Sortable.create(this.element, {
    group:"list",
    onEnd: this.end.bind(this)
  })
}

end(event) {
  let image = event.item.dataset.id
  let template = event.to.dataset.template
  let artiste_id = event.to.dataset.artiste_id
  let block_id = event.to.dataset.block_id
  let position = event.to.dataset.position
  Rails.ajax({
    url:`/artistes/update_image_template/${artiste_id}?image_id=${image}&template=${template}&position=${position}&block_id=${block_id}`,
    type:'POST'
  })
  }
}
