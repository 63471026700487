import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import $ from 'jquery';

export default class extends Controller {
static targets = [ "expo_id", "template_id" ]

  update() {
    this.exposition_id= this.data.get("expo_id");
    this.template_id = this.data.get("template_id");

          $.ajax({
                url: '/expositions/template_exposition',
                type: "POST",
                beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                data: { template_id: this.template_id, exposition_id: this.exposition_id},
                success: function (){
                console.log("")
                },
                async: true
              } );
          }

  }
