import { Controller } from "stimulus"
import $ from 'jquery';

import Select2 from "select2"
export default class extends Controller {
  static targets = [ "select", "url" ]

  connect() {
    this.select2mount()
    document.addEventListener("turbolinks:before-cache", () => {
      this.select2unmount()
    }, { once: true })

  }

  select2mount() {
    const placeholder = $(this.selectTarget).data('placeholder')
    const dropdownParent = $(this.selectTarget).data('parent')
    const minimum = $(this.selectTarget).data('minimum')
    const id = $(this.selectTarget).data('collectionneur')


      $(this.selectTarget).select2({
        placeholder: placeholder,
        minimumResultsForSearch: minimum,
        dropdownParent: dropdownParent,
        tags: true,
        tokenSeparators: [',', ' ']
        }).on("change", function(e) {
          var isNew = $(this).find('[data-select2-tag="true"]');
          if(isNew.length && $.inArray(isNew.val(), $(this).val()) !== -1){
              isNew.replaceWith('<option selected value="'+isNew.val()+'">'+isNew.val()+'</option>');
              $.ajax({
                  url:  '/add_tag_to_collectionneur',
                  type: "PATCH",
                  beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                  data: { tag: isNew.val(), collectionneur: id},
                  async: true
              });
          }
      }).on('select2:select', function (e) {
          $.ajax({
          url:  '/update_tag_to_collectionneur',
          type: "PATCH",
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          data: { tag: e.params.data.id, collectionneur: id, select:"add"},
          async: true
        });
      }).on('select2:unselect', function (e) {
          $.ajax({
          url:  '/update_tag_to_collectionneur',
          type: "PATCH",
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          data: { tag: e.params.data.id, collectionneur: id, select:"remove"},
          async: true
        });
      });
  }


  select2unmount() {
  $(this.selectTarget).select2('destroy');
  }



}
