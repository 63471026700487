import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import $ from 'jquery';
export default class extends Controller {
  static targets = [ "cardodd", "cardeven", "ratioodd", "ratioeven" ]

  connect()
  {


     this.ratioodd = this.ratiooddTarget.innerText;
     if (this.hasRatioevenTarget)
     {
     this.ratioeven = this.ratioevenTarget.innerText;
     this.cardeven = this.cardevenTarget;
     var ratio_2 =  parseFloat(this.ratioeven);
      }
     this.cardodd = this.cardoddTarget;
     var window_width = $("#container_artworks").width();
     var ratio_1 =  parseFloat(this.ratioodd);
     if (this.hasRatioevenTarget)
     {
     var height = (window_width - 40) *(ratio_1 * ratio_2) / (ratio_1 + ratio_2)
     var width_1 = height/ratio_1
     var width_2 = height/ratio_2

         this.cardodd.style.width = width_1 + "px"
         this.cardodd.style.height = height + "px"
         this.cardeven.style.width = width_2 + "px"
         this.cardeven.style.height = height + "px"

      }
      else
      {
        var height = (window_width - 40)/2 * ratio_1
        var width_1 = height/ratio_1

            this.cardodd.style.width = width_1 + "px"
            this.cardodd.style.height = height + "px"

      }




  }
}
