import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import $ from 'jquery';

export default class extends Controller {
  static targets = ["url"]
    connect() {
        $("#select_modal_publication_artiste").prop("disabled", true);
        $("#select_modal_publication_expo").on("select2:select", function(e) {
        $("#select_modal_publication_artiste").prop("disabled", false);

        var expo_id = e.params.data.id;
        var text = e.params.data.text.split(" / ")
        var titre = text[0]
        var year = text[1]

        $("#publication_exposition_titre").val(titre);
        $("#publication_years").val(year);
        $.get( "/publications/new.json", { expo_id: expo_id }, function( data ) {
        $("#select_modal_publication_artiste").html("");
      // Loop over the json and populate the Responses options:
        $("#select_modal_publication_artiste").append( "<option >Sélectionner un Artiste</option>" );
        $.each(data, function( index, artiste ) {
          $("#select_modal_publication_artiste").append( "<option value='" + artiste.id + "'>" + artiste.prenom.toUpperCase()  +" "+ artiste.nom.toUpperCase()  + "</option>" );
        });
        });
        });
        $("#select_modal_publication_artiste").on("select2:select", function(e) {
            $("#publication_artiste_name").val(e.params.data.text);
        });

      }
    }
