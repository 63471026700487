import { Controller } from 'stimulus';
import Carousel from "stimulus-carousel"
import AOS from 'aos'
export default class extends Controller {

connect() {
     new Swiper('.swiper-container', {
       loop:true,
       observer:true,
       effect : "fade",
       autoplay:{
         delay: 2500,
         disableOnInteraction: false
          },
        speed: 1000,
        fadeEffect: { crossFade: true },
        on: {
            slideChangeTransitionStart: function () {
            }
          }
  });

  }

}
