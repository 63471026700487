import { Controller } from "stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this)
    })

  }

  end(event) {
    let data = new FormData()
    let neworder = new Array()

    $("#block_list").children().each(function() {
    var id = $(this).attr("id");
    neworder.push(id)});

    let email_id = this.data.get("id")

    //data.append("position", event.newIndex + 1)
    data.append("order", JSON.stringify(neworder))
    data.append("expo_id", email_id )


    Rails.ajax({
      url: this.data.get("url"),
      type: 'POST',
      data: data
    })
  }
}
