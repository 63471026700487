import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import $ from 'jquery'

export default class extends Controller {
static targets = ["url", "block"]

  connect() {


  }

  submit()
  {
    this.url = this.data.get("url");
    this.block = this.data.get("block");
    var block = this.block
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      $.ajax({
        url:  '/expositionemail/size_text',
        type: "PATCH",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {size_text: $(`#size_text_${this.block}`).val(), expositionemail_id: document.getElementById("expositionemail_selection").getAttribute('value'),text_exposition_id: block},
        async: true
        });
    }, 500)

  }

  submit_image()
  {
    this.url = this.data.get("url");
    this.block = this.data.get("block");
    var block = this.block
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      $.ajax({
        url:  '/expositionemail/size_image',
        type: "PATCH",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {image_size: $(`#size_image_${this.block}`).val(), expositionemail_id: document.getElementById("expositionemail_selection").getAttribute('value'),text_exposition_id: block},
        async: true
        });
    }, 500)

  }


}
