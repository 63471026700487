import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import $ from 'jquery';

export default class extends Controller {
static targets = [ "collectionneur" ]




  connect() {
    var pathname_fr = $("#selection_coll_fr").attr('href');
    var urlParams = new URLSearchParams(pathname_fr);
    var collectionneur = urlParams.get('collectionneurs');
    if(collectionneur != "")
    {
    var collectionneur_array = collectionneur.split(",");
    $.each(collectionneur_array, function( index, value ) {
      $("#collectionneur_section").find(`[data-id='${value}']`).addClass("select_list");
    });
  }


    $("#selection_list_modal").on("select2:select select2:unselect", function(e) {
      var selection = e.params.data.id;
      var pathname_fr = $("#selection_coll_fr").attr('href');
      var urlParams = new URLSearchParams(pathname_fr);
      var list = urlParams.get('list_id');

       if (list == "")
       {
         var list_array = [];
       }
       else
       {
         var list_array = list.split(",");
       }

           if (list_array.includes(String(selection)))
         {
             var url_fr = new URL(pathname_fr);
             list_array.splice(list_array.indexOf(selection), 1);
             url_fr.searchParams.set("list_id", list_array); // setting your param
             var newUrl_fr = url_fr.href;
             $("#selection_coll_fr").attr("href", newUrl_fr);
            }
          else
          {
            var url_fr = new URL(pathname_fr);
            list_array.push(selection);
            url_fr.searchParams.set("list_id", list_array); // setting your param
            var newUrl_fr = url_fr.href;
            $("#selection_coll_fr").attr("href", newUrl_fr);

          }

      });

    $("#selection_artiste_modal").on("select2:select select2:unselect", function(e) {
      var selection = e.params.data.id;
      var pathname_fr = $("#selection_coll_fr").attr('href');
      var urlParams = new URLSearchParams(pathname_fr);
      var list = urlParams.get('artistes');
       if (list == "")
       {
         var list_array = [];
       }
       else
       {
         var list_array = list.split(",");
       }

           if (list_array.includes(String(selection)))
         {
             var url_fr = new URL(pathname_fr);
             list_array.splice(list_array.indexOf(selection), 1);
             url_fr.searchParams.set("artistes", list_array); // setting your param
             var newUrl_fr = url_fr.href;
             $("#selection_coll_fr").attr("href", newUrl_fr);
            }
          else
          {
            var url_fr = new URL(pathname_fr);
            list_array.push(selection);
            url_fr.searchParams.set("artistes", list_array); // setting your param
            var newUrl_fr = url_fr.href;
            $("#selection_coll_fr").attr("href", newUrl_fr);

          }


      });

    $("#tags_collectionneur_modal").on("select2:select select2:unselect", function(e) {
      var selection = e.params.data.id;
      var pathname_fr = $("#selection_coll_fr").attr('href');
      var urlParams = new URLSearchParams(pathname_fr);
      var list = urlParams.get('tags');
       if (list == "")
       {
         var list_array = [];
       }
       else
       {
         var list_array = list.split(",");
       }

           if (list_array.includes(String(selection)))
         {
             var url_fr = new URL(pathname_fr);
             list_array.splice(list_array.indexOf(selection), 1);
             url_fr.searchParams.set("tags", list_array); // setting your param
             var newUrl_fr = url_fr.href;
             $("#selection_coll_fr").attr("href", newUrl_fr);
            }
          else
          {
            var url_fr = new URL(pathname_fr);
            list_array.push(selection);
            url_fr.searchParams.set("tags", list_array); // setting your param
            var newUrl_fr = url_fr.href;
            $("#selection_coll_fr").attr("href", newUrl_fr);

          }


      });




  }

  add() {


    var pathname_fr = null;
    var urlParams =null;
    var newUrl = null;
    var collectionneur_id = null;

    var pathname_fr = $("#selection_coll_fr").attr('href');
    var collectionneur_id = $(this.collectionneurTarget).data('id');

     var urlParams = new URLSearchParams(pathname_fr);
     var collectionneur = urlParams.get('collectionneurs');
     if (collectionneur == "")
     {
       var collectionneur_array = [];
     }
     else
     {
       var collectionneur_array = collectionneur.split(",");
     }



         if (collectionneur_array.includes(String(collectionneur_id)))
       {
           var url_fr = new URL(pathname_fr);
           collectionneur_array.splice(collectionneur_array.indexOf(collectionneur_id), 1);
           url_fr.searchParams.set("collectionneurs", collectionneur_array); // setting your param
           var newUrl_fr = url_fr.href;
           $("#selection_coll_fr").attr("href", newUrl_fr);
          }
        else
        {
          var url_fr = new URL(pathname_fr);
          collectionneur_array.push(collectionneur_id);
          url_fr.searchParams.set("collectionneurs", collectionneur_array); // setting your param
          var newUrl_fr = url_fr.href;
          $("#selection_coll_fr").attr("href", newUrl_fr);

        }

    $(this.collectionneurTarget).toggleClass("select_list");


}

  add_all() {

    var pathname_fr = null;
    var urlParams =null;
    var newUrl = null;
    var selectedIds = [];
    var pathname_fr = $("#selection_coll_fr").attr('href');
    var urlParams = new URLSearchParams(pathname_fr);
    var collectionneur = urlParams.get('collectionneurs');

      if ($("#parent_checkbox").is(':checked')) {
        $('.children').each(function() {
          $(this).prop('checked', true); // Check all child checkboxes
          var collectionneurId = $(this).closest('td').data('id');
          selectedIds.push(collectionneurId); // Add ID to selectedIds array
        });
      } else {
        $('.children').each(function() {
          $(this).prop('checked', false); // Uncheck all child checkboxes
        });
        selectedIds = []; // Clear the array if "select all" checkbox is unchecked
      }

      var url_fr = new URL(pathname_fr);
      url_fr.searchParams.set("collectionneurs", selectedIds); // setting your param
      var newUrl_fr = url_fr.href;
      $("#selection_coll_fr").attr("href", newUrl_fr);

}

}
