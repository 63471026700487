import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import Sortable from "sortablejs"

export default class extends Controller {
static targets = [ 'status', 'id' ,'url', 'id_origin']
connect() {
    this.sortable = Sortable.create(this.element, {
    group:"list",
    onStart: this.start.bind(this),
    onEnd: this.end.bind(this),
  })
}
start(event) {
  this.url = this.data.get('url');
  let id_origin = event.from.dataset.id
}


end(event) {
  let id = event.item.dataset.id
  let id_origin = event.from.dataset.id
  let status = event.to.dataset.id
  console.log(event)

  this.url = this.data.get('url');
  if(this.url=="/oeuvres/update_status")
  {
    {
      let text = `Valider la modification de l'oeuvre de "${id_origin}" vers "${status}" ?`
      if (confirm(text) == true) {
        Rails.ajax({
          url:`/oeuvres/update_status/${id}?status=${status}`,
          type:'GET'
        })
         $(`#${id}`).addClass("oeuvre_moved");
      }
      else
      {
        Rails.ajax({
          url:`/oeuvres/update_status/${id}?status=${status}&origin=${id_origin}&validation=cancel`,
          type:'GET'
        })
      }
    }


  }
  else if(this.url=="/expositions/update_position")
  {
    Rails.ajax({
      url:`/expositions/update_position/${id}?position=${status}`,
      type:'PATCH'
    })
  }
}


}
