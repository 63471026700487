import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import Sortable from "sortablejs"

export default class extends Controller {
static targets = [ 'status', 'id' ,'url', 'id_origin']
connect() {
    this.sortable = Sortable.create(this.element, {
    group:"list",
    onStart: this.start.bind(this),
    onEnd: this.end.bind(this)
  })
}

start(event) {
  let livraison = event.item.dataset.livraion

  if(livraison == "retrait")
  {
      $('#livraison_column').hide(500);
      $('#reception_column').hide(500);
      $('#pipe_livraison').hide(500);
      $('#pipe_reception').hide(500);
  }
}

end(event) {
  let id = event.item.dataset.id
  let status = event.to.dataset.id
  let livraison = event.item.dataset.livraion
  if(livraison == "retrait")
  {
      $('#livraison_column').show(500);
      $('#reception_column').show(500);
      $('#pipe_livraison').show(500);
      $('#pipe_reception').show(500);
  }
  Rails.ajax({
    url:`/orders/update_status/${id}?status=${status}`,
    type:'PATCH'
  })
  }
}
