// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "expo_id", "oeuvre_id", "card" ]

  connect() {
  }

  add() {
        this.exposition_id = this.data.get("expo_id");
        this.oeuvre_id = this.data.get("oeuvre_id");
        this.cardTarget.classList.toggle("border-blue");
        this.makeRequest();
    }

  makeRequest() {
    Rails.ajax({
      type: "patch",
      url: `/expositions/${this.exposition_id}/oeuvres_exposition?oeuvre_id=${this.oeuvre_id}`
    })
  }

}
