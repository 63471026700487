import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage";
import * as ActiveStorage from "@rails/activestorage"


export default class extends Controller {
  static targets = ["file","input", "progress", "save"];

  uploadFile() {
    if (Array.from(this.inputTarget.files).length >= 2)
    {
      var array = []
      Array.from(this.inputTarget.files).forEach((file) => {
        const upload = new DirectUpload(
          file,
          this.inputTarget.dataset.directUploadUrl,
          this //callback directUploadWillStoreFileWithXHR(request)
        );
        upload.create((error, blob) => {
          if (error) {
             console.log(error);
          }
          else
          {
            array.push(blob.key)
            if(array.length == Array.from(this.inputTarget.files).length)
            {
              console.log(array)
              this.updateImageSavedArray(array)
            }
          }
        });
      });

    }
    else {
      Array.from(this.inputTarget.files).forEach((file) => {
        const upload = new DirectUpload(
          file,
          this.inputTarget.dataset.directUploadUrl,
          this //callback directUploadWillStoreFileWithXHR(request)
        );
        upload.create((error, blob) => {
          if (error) {
             console.log(error);
          } else
          {
            this.updateImageSaved(blob.key)
          }
        });
      });
    }
  }


  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => {
      this.progressUpdate(event);
    });
  }

  progressUpdate(event) {
    this.progressTarget.classList.remove('hidden');
    const progress = (event.loaded / event.total) * 100;
    this.progressTarget.classList.remove('direct-upload--pending');
    console.log(this.progressTarget, this.progressTarget.children)
    this.progressTarget.childNodes[0].style.width = `${progress}%`
    this.progressTarget.childNodes[0].innerHTML = "Téléchargement: " + progress.toFixed(0) + " %";
    if(progress== 100)
    {
      this.progressTarget.classList.add('direct-upload--complete');
      setTimeout(()=> {
               this.progressTarget.classList.add('hidden')
      }
      ,1500);
    }

    // if you navigate away from the form, progress can still be displayed
    // with something like this:
    // document.querySelector("#global-progress").innerHTML = progress;
  }


  updateImageSavedArray(image,image_type){
    var model_id = $("#model_id").text();
    var image_type = this.inputTarget.name
    var model = image_type.split("[")[0]
    var instance = image_type.split("[")[1].split("]")[0]

    //if(image_type == "email[file_attach][]")
    //{
//      var email_id = $("#email_id_update").text();
//      Rails.ajax({
//        type: "patch",
//        url: `/emails/update_files/${email_id}?image=${image}`
//      })
//    }
//    else if(image_type == "email_projet_invest[file_attach][]")
//    {
//      var email_id = $("#email_projet_invest_id_update").text();
//      Rails.ajax({
//        type: "patch",
//        url: `/email_projet_invests/update_files/${email_id}?image=${image}`
//      })
//    }
      $.ajax({
          url: '/update_image_model',
          type: "POST",
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          data: { model: model, instance: instance, model_id: model_id, image: image},
          success: function (){
          console.log("")
          },
          async: true
          });


  }

  updateImageSaved(image,image_type){
    var model_id = $("#model_id").text();
    var image_type = this.inputTarget.name
    var model = image_type.split("[")[0]
    var instance = image_type.split("[")[1].split("]")[0]
    if (model == "oeuvre_templates_info")
    {
      var model_id = $("#model_id_certificat").text();
    }
    else
    {
      var model_id = $("#model_id").text();
    }
    console.log(model, instance)
    //if (image_type == "projet_invest_oeuvre[image_oeuvre]")
    //{
    //  $('#spinner').css('display', 'flex');
    //  $('#spinner-load').hide();
    //  Rails.ajax({
    //    type: "patch",
    //    url: `/projet_invest_oeuvres/update_images_direct/${oeuvre_id}?type=${image_type}&image=${image}`
    //  })
    //}
    //else if(image_type == "projet_invest_oeuvre[image_crop]")
    //{
    //  $('#spinner').css('display', 'flex');
    //  $('#spinner-load').hide();
    //  Rails.ajax({
    //    type: "patch",
    //    url: `/projet_invest_oeuvres/update_images_direct/${oeuvre_id}?type=${image_type}&image=${image}`
    //  })
    //}
    //else if(image_type == "email[file_attach][]")
    //{
    //  var email_id = $("#email_id_update").text();
    //  Rails.ajax({
    //    type: "patch",
    //    url: `/emails/update_files/${email_id}?image=${image}`
    //  })
    //}
    //else if(image_type == "email_projet_invest[file_attach][]")
    //{
    //  var email_id = $("#email_projet_invest_id_update").text();
    //  Rails.ajax({
    //    type: "patch",
    //    url: `/email_projet_invests/update_files/${email_id}?image=${image}`
    //  })
    //}
    //else {

    $.ajax({
        url: '/update_image_model',
        type: "POST",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: { model: model, instance: instance, model_id: model_id, image: image},
        success: function (){
        console.log("")
        },
        async: true
        });
    }


}
