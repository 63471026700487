import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import Select2 from "select2"
import $ from 'jquery';

export default class extends Controller {
static targets = ["url", "block", "product_id", "locale"]
  connect() {
      this.url = this.data.get("url");


      if (this.url=="/expositions/template_exposition")
      {
        $("#selection_expo").on("select2:select", function(e) {
          $.ajax({
                url: '/expositions/template_exposition',
                type: "POST",
                beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                data: { template_id: e.params.data.id, exposition_id: document.getElementById("artistes_selection").getAttribute('value')},
                success: function (){
                console.log("")
              },
              async: true
            });
          });
      }

      else if (this.url=="/oeuvres/status_update")
      {
        $("#status_new").on("select2:select", function(e) {
          $.ajax({
                url: '/oeuvres/status_update',
                type: "POST",
                beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                data: { status_id: e.params.data.id, oeuvre_id: document.getElementById("oeuvres_selection").getAttribute('value')},
                success: function (){
                console.log("")
              },
              async: true
            });
          });
      }



      else if (this.url=="/expositions/artistes_exposition_add")
      {
        $("#selection_artiste").on("select2:select select2:unselect", function(e) {
            $.ajax({
                url:  '/expositions/artistes_exposition_add',
                type: "POST",
                beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                data: { artiste_id: e.params.data.id, exposition_id: document.getElementById("artistes_selection").getAttribute('value')},
                success: function (){
                console.log("")
              },
              async: true
            });
          });
      }

      else if (this.url=="/products/artistes_product_add")
      {
        $("#selection_artiste").on("select2:select select2:unselect", function(e) {
            $.ajax({
                url:  '/products/artistes_product_add',
                type: "POST",
                beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                data: { artiste_id: e.params.data.id, product_id: document.getElementById("artistes_selection").getAttribute('value')},
                success: function (){
                console.log("")
              },
              async: true
            });
          });
      }

      else if (this.url=="/publications/artistes_publication_add")
      {
        $("#selection_artiste").on("select2:select select2:unselect", function(e) {
            $.ajax({
                url:  '/publications/artistes_publication_add',
                type: "POST",
                beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                data: { artiste_id: e.params.data.id, publication_id: document.getElementById("artistes_selection").getAttribute('value')},
                success: function (){
                console.log("")
              },
              async: true
            });
          });
      }

      else if (this.url=="/emails/emailoeuvre")
      {
      $("#selection_artiste").on("select2:select select2:unselect", function(e) {
          $.ajax({
              url:  '/emailoeuvre',
              type: "GET",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: { artiste_id: e.params.data.id, email_id: document.getElementById("email_id").getAttribute('value')},
              success: function (){
              console.log("")
            },
            async: true
          });
        });
      }

      else if (this.url=="/emails/list_diffusion")
      {
      $("#list_diffusion").on("select2:select select2:unselect", function(e) {
          $.ajax({
              url:  '/emails/list_diffusion',
              type: "POST",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: { list_id: e.params.data.id, email_id: document.getElementById("model_id").innerHTML},
              async: true
          });
        });
      }

      else if (this.url=="/emails/contact_update")
      {
      $("#collectionneur_list").on("select2:select select2:unselect", function(e) {
          $.ajax({
              url:  '/emails/contact_update',
              type: "POST",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: { collectionneur_id: e.params.data.id, email_id: document.getElementById("model_id").innerHTML},
              async: true
          });
        });
      }

      else if (this.url=="/emails/artistes")
      {
      $("#artiste_diffusion").on("select2:select select2:unselect", function(e) {
          $.ajax({
              url:  '/emails/artistes',
              type: "POST",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: { artiste_id: e.params.data.id, email_id: document.getElementById("model_id").innerHTML},

            async: true
          });
        });
      }


      else if (this.url=="/newsletter/list_diffusion")
      {
      $("#list_diffusion").on("select2:select select2:unselect", function(e) {
          $.ajax({
              url:  '/newsletter/list_diffusion',
              type: "POST",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: { artiste_id: e.params.data.id, newsletter_id: document.getElementById("newsletter_id").getAttribute('value')},
              async: true
          });
        });
      }

      else if (this.url=="/emails/tags")
      {
      $("#list_tags").on("select2:select select2:unselect", function(e) {
          $.ajax({
              url:  '/emails/tags',
              type: "POST",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: { tags: e.params.data.id, email_id: document.getElementById("model_id").innerHTML},
            async: true
          });
        });
      }


      else if (this.url=="/exposition/background_position")
      {
      $("#image_background_position_select_expo").on("select2:select select2:unselect", function(e) {
          $.ajax({
              url:  '/exposition/background_position',
              type: "POST",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: { background_1: e.params.data.id, exposition_id: document.getElementById("artistes_selection").getAttribute('value')},
              async: true
          });
        });
      }


      else if (this.url=="/exposition/background2_position")
      {
      $("#image_background2_position_select_expo").on("select2:select select2:unselect", function(e) {
          $.ajax({
              url:  '/exposition/background_position',
              type: "POST",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: { background_2: e.params.data.id, exposition_id: document.getElementById("artistes_selection").getAttribute('value')},
              async: true
          });
        });
      }

      else if (this.url=="/expositionemail/type_text")
      {
      this.block = this.data.get("block");
      var block = this.block

      $(`#type_text_${this.block}`).on("select2:select select2:unselect", function(e) {

          $.ajax({
              url:  '/expositionemail/type_text',
              type: "PATCH",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: {type_text: e.params.data.id, expositionemail_id: document.getElementById("expositionemail_selection").getAttribute('value'), text_exposition_id: block},
              async: true
          });
        });
      }

      else if (this.url=="/expositionemail/position_text")
      {
      this.block = this.data.get("block");
      var block = this.block

      $(`#position_text_${this.block}`).on("select2:select select2:unselect", function(e) {

          $.ajax({
              url:  '/expositionemail/position_text',
              type: "PATCH",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: {position_text: e.params.data.id, expositionemail_id: document.getElementById("expositionemail_selection").getAttribute('value'),text_exposition_id: block},
              async: true
          });
        });
      }

      else if (this.url=="/product/update_status")
      {

      $(`#product_select_status`).on("select2:select select2:unselect", function(e) {

          $.ajax({
              url:  '/product/update_status',
              type: "GET",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: {status: e.params.data.id, product_id: document.getElementById("model_id").innerText},
              async: true
          });
        });
      }

      else if (this.url=="/product/update_sizeable")
      {

      $(`#product_sizeable`).on("select2:select select2:unselect", function(e) {

          $.ajax({
              url:  '/product/update_sizeable',
              type: "GET",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: {status: e.params.data.id, product_id: document.getElementById("model_id").innerText},
              async: true
          });
        });
      }

      else if (this.url=="/product/select_size")
      {
        var product_id = this.data.get("product_id")
        var locale = this.data.get("locale")

      $(`#product_select_size_${product_id}`).on("select2:select select2:unselect", function(e) {
          $.ajax({
              url:  '/product/select_size',
              type: "GET",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: {status: e.params.data.id, product_id: product_id, locale:locale },
              async: true
          });
        });
      }


      else if (this.url=="/product/update_status_shop")
      {

      $(`#product_select_status_shop`).on("select2:select select2:unselect", function(e) {

          $.ajax({
              url:  '/product/update_status_shop',
              type: "GET",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: {status: e.params.data.id, product_id: document.getElementById("model_id").innerText},
              async: true
          });
        });
      }

      else if (this.url=="/product/update_tva")
      {

      $(`#product_select_tva`).on("select2:select select2:unselect", function(e) {

          $.ajax({
              url:  '/product/update_tva',
              type: "GET",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: {tva: e.params.data.id, product_id: document.getElementById("model_id").innerText},
              async: true
          });
        });
      }


      else if (this.url=="/collectionneurs_artistes")
      {
      $("#selection_artiste").on("select2:select select2:unselect", function(e) {

       Rails.fire(this.form, 'submit');
      });
      }
      else if (this.url=="/collectionneurs_tags")
      {
      $("#tags_collectionneur").on("select2:select select2:unselect", function(e) {

       Rails.fire(this.form, 'submit');
      });
      }
      else if (this.url=="/collectionneurs_lists")
      {
      $("#selection_list").on("select2:select select2:unselect", function(e) {

       Rails.fire(this.form, 'submit');
      });
      }

      else if (this.url=="/states")
      {
      $("#select_modal_country").on("select2:select select2:unselect", function(e) {
        $.getJSON('/states/' + e.params.data.id, function (data) {
          var $select2 = $('#select_modal_country_state');
          $select2.empty().trigger("change");
          $.each(data, function (i,name) {
            var newOption = new Option(name, i, false, false);
            $select2.append(newOption).trigger('change');
          });
        });
      });
      }

      else if (this.url=="/cart_item/update_quantity")
      {
      this.block = this.data.get("block");
      var block = this.block

      $(`#quantity_selector_${this.block}`).on("select2:select select2:unselect", function(e) {

          $.ajax({
              url:  '/cart_item/update_quantity',
              type: "PATCH",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: {quantity: e.params.data.id,cart_item_id: block},
              async: true
          });
        });
      }

      else if (this.url=="/cart_item/update_quantity")
      {
      this.block = this.data.get("block");
      var block = this.block

      $(`#quantity_selector_${this.block}`).on("select2:select select2:unselect", function(e) {

          $.ajax({
              url:  '/cart_item/update_quantity',
              type: "PATCH",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: {quantity: e.params.data.id,cart_item_id: block},
              async: true
          });
        });
      }

      else if (this.url=="/list_selection")
      {
      $(`#list_select`).on("select2:select select2:unselect", function(e) {
        var pathname_en = null;
        var pathname_en = $("#list_link").attr('href');
        var list_id = e.params.data.id
        var url_fr = new URL(pathname_en);
        url_fr.searchParams.set("list", list_id); // setting your param
        var newUrl_fr = url_fr.href;
        $("#list_link").attr("href", newUrl_fr);

      });
    }

    else if (this.url=="/list_selection_produit")
    {
    $(`#list_select_produit`).on("select2:select select2:unselect", function(e) {
      var pathname_en = null;
      var pathname_en = $("#factsheet_en").attr('href');
      var list_id = e.params.data.id
      var url_fr = new URL(pathname_en);
      url_fr.searchParams.set("list", list_id); // setting your param
      var newUrl_fr = url_fr.href;
      $("#factsheet_en").attr("href", newUrl_fr);

    });
  }

    else if (this.url=="/list_selection_export")
    {
    $(`#list_select_export`).on("select2:select select2:unselect", function(e) {
      var pathname_ex = null;
      var pathname_ex = $("#list_link_export").attr('href');
      var list_id = e.params.data.id
      var url_fxr = new URL(pathname_ex);
      url_fxr.searchParams.set("list", list_id); // setting your param
      var newUrl_fr = url_fxr.href;
      $("#list_link_export").attr("href", newUrl_fr);

    });
  }

    else if (this.url=="/expositions/update_oeuvre_block")
     {
     var block_id = this.data.get("id");
     $(`#oeuvre_${block_id}`).on("select2:select select2:unselect", function(e) {
         $.ajax({
             url:  '/expositions/update_oeuvre_block',
             type: "POST",
             beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
             data: { choice: e.params.data.id, transaction_id: block_id},
             async: true,
         });
       });
     }
     else if (this.url=="/oeuvres/update_collectionneur")
      {
      var oeuvre_id = this.data.get("id");
      $(`#oeuvre_collectionneur_select`).on("select2:select select2:unselect", function(e) {
          $.ajax({
              url:  '/oeuvres/update_collectionneur',
              type: "POST",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: { choice: e.params.data.id, oeuvre_id: oeuvre_id},
              async: true,
          });
        });
      }
      else if (this.url=="/oeuvres/update_consignataire")
       {
       var oeuvre_id = this.data.get("id");
       $(`#oeuvre_consignataire`).on("select2:select select2:unselect", function(e) {
           $.ajax({
               url:  '/oeuvres/update_consignataire',
               type: "POST",
               beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
               data: { choice: e.params.data.id, oeuvre_id: oeuvre_id},
               async: true,
           });
         });
       }
      else if (this.url=="/oeuvres/localisation_status")
       {
       var oeuvre_id = this.data.get("id");
       console.log(oeuvre_id)
       $(`#localisation_oeuvre_${oeuvre_id}`).on("select2:select select2:unselect", function(e) {
         if (e.params.data.id == "rue_du_mail")
         { $(`[data-id="${oeuvre_id}"]`).css('border', '1px solid #FF6347');}
         else
         { $(`[data-id="${oeuvre_id}"]`).css('border', '1px solid #000085');}
           $.ajax({
               url:  '/oeuvres/localisation_status',
               type: "POST",
               beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
               data: { choice: e.params.data.id, oeuvre_id: oeuvre_id},
               async: true,
           });
         });
       }

  }
}
