import { Controller } from "stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let artiste_id = this.data.get("artiste")
    let data = new FormData()
    let neworder = new Array()
    $("#oeuvre-list").children().each(function() {
    var id = $(this).attr("id");
    neworder.push(id)});
    data.append("order", JSON.stringify(neworder))
    data.append("artiste_id", artiste_id )

    Rails.ajax({
      url: "/oeuvres_artiste/list_update",
      type: 'PATCH',
      data: data
    })
  }
}
