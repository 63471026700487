import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import $ from 'jquery';

export default class extends Controller {

    connect() {
      $("#select_modal_publication_expo").prop("disabled", true);
      var select_type_id = $("#publication_type").find('option:selected').first().val();
      if (select_type_id == "catalogue_exposition")
      {
      $("#select_modal_publication_expo").prop("disabled", false);
      $("#select_modal_publication_artiste").on("select2:select", function(e) {
          $("#publication_artiste_name").val(e.params.data.text);
      });
      }
      else
      {
      $("#select_modal_publication_artiste").prop("disabled", false);
      $("#select_modal_publication_expo").prop("disabled", true);
      $("#select_modal_publication_expo").val('').change();
      $("#publication_years").val('');
      $.get( "/publications/new.json", function( data ) {
      $("#select_modal_publication_artiste").html("");
    // Loop over the json and populate the Responses options:
      $("#select_modal_publication_artiste").append( "<option >Sélectionner un Artiste</option>" );
      $.each(data, function( index, artiste ) {
        $("#select_modal_publication_artiste").append( "<option value='" + artiste.id + "'>" + artiste.prenom.toUpperCase()  +" "+ artiste.nom.toUpperCase()  + "</option>" );
      });
      });
      $("#select_modal_publication_artiste").on("select2:select", function(e) {
          $("#publication_artiste_name").val(e.params.data.text);
      });
      }


      $("#publication_type").on("select2:select", function(e) {

      var type_id = e.params.data.id;
      if (type_id == "catalogue_exposition")
      {
      $("#select_modal_publication_expo").prop("disabled", false);
      $("#select_modal_publication_artiste").on("select2:select", function(e) {
          $("#publication_artiste_name").val(e.params.data.text);
      });
      }
      else
      {
      $("#select_modal_publication_artiste").prop("disabled", false);
      $("#select_modal_publication_expo").prop("disabled", true);
      $("#select_modal_publication_expo").val('').change();
      $("#publication_years").val('');
      $.get( "/publications/new.json", function( data ) {
      $("#select_modal_publication_artiste").html("");
    // Loop over the json and populate the Responses options:
      $("#select_modal_publication_artiste").append( "<option >Sélectionner un Artiste</option>" );
      $.each(data, function( index, artiste ) {
        $("#select_modal_publication_artiste").append( "<option value='" + artiste.id + "'>" + artiste.prenom.toUpperCase()  +" "+ artiste.nom.toUpperCase()  + "</option>" );
      });
      });
      $("#select_modal_publication_artiste").on("select2:select", function(e) {
          $("#publication_artiste_name").val(e.params.data.text);
      });
      }

    });
    }
  }
