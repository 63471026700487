import { Controller } from "stimulus"
import Rails from "@rails/ujs"
export default class extends Controller {
  static targets = [ "id", "display", "url", "courrier", "pricedelivery"]

  connect(){

    }

  update() {
        this.id= this.data.get("id");
        this.display = this.data.get("display");
        this.url = this.data.get("url");
        this.courrier = this.data.get("courrier");
        this.pricedelivery = this.data.get("pricedelivery");
        if (this.url == "/artistes")
        {
        this.makeRequest_artiste();
        }
        else if (this.url == "/oeuvres")
        {
        this.makeRequest_oeuvre();
        }
        else if (this.url == "/expositions")
        {
        this.makeRequest_expo();
        }
        else if (this.url == "/publications")
        {
        this.makeRequest_publication();
        }
        else if (this.url == "/publications/hightlight")
        {
        this.makeRequest_publication_highlights();
        }
        else if (this.url == "/products")
        {
        this.makeRequest_product();
        }
        else if (this.url == "/products_archived")
        {
        this.makeRequest_product_archived();
        }
        else if (this.url == "/news")
        {
        this.makeRequest_news();
        }
        else if (this.url == "/products/update_best_sales")
        {
        this.makeRequest_product_sales();
        }
        else if (this.url == "/products/update_nouveaute")
        {
        this.makeRequest_product_nouveaute();
        }
        else if (this.url == "/order_internes/update_order_interne")
        {
        this.makeRequest_order_payement();
        }
        else if (this.url == "/orders/update_order_interne")
        {
        this.makeRequest_order_shipping();
        }
        else if (this.url == "/oeuvres/update_localisation")
        {
        this.makeRequest_oeuvre_localisation();
        }
        else if (this.url == "/artistes/update_builder")
        {
        this.makeRequest_artiste_builder();
        }
        else if (this.url == "/expositions/update_display_product")
        {
        this.makeRequest_exposition_product();
        }
        else if (this.url == "/orders/update_delivery_method")
        {
        this.makeRequest_order_delivery();
        let elements = document.getElementsByClassName('selected-payement-2');
        if(elements.length > 0)
        {
        for (let i = 0; i < elements.length + 1; i++) {
            (elements[i].classList.remove("selected-payement-2"));
        }
        this.element.classList.add("selected-payement-2")
        }
      }
    }

  makeRequest_artiste() {
    Rails.ajax({
      type: "patch",
      url: `/artistes/update_display/${this.id}?display_choice=${this.display}`
    })
  }
  makeRequest_oeuvre() {
    Rails.ajax({
      type: "patch",
      url: `/oeuvres/update_display/${this.id}?display_choice=${this.display}`
    })
  }
  makeRequest_expo() {
    Rails.ajax({
      type: "patch",
      url: `/expositions/update_display/${this.id}?display_choice=${this.display}`
    })
  }
  makeRequest_publication() {
    Rails.ajax({
      type: "patch",
      url: `/publications/update_display/${this.id}?display_choice=${this.display}`
    })
  }
  makeRequest_publication_highlights() {
    Rails.ajax({
      type: "patch",
      url: `/publications/hightlight/${this.id}?display_choice=${this.display}`
    })
  }
  makeRequest_news() {
    Rails.ajax({
      type: "patch",
      url: `/news/update_display/${this.id}?display_choice=${this.display}`
    })
  }
  makeRequest_product() {
    Rails.ajax({
      type: "patch",
      url: `/products/update_display/${this.id}?display_choice=${this.display}`
    })
  }
  makeRequest_product_archived() {
    Rails.ajax({
      type: "patch",
      url: `/products/update_archived/${this.id}?choice=${this.display}`
    })
  }

  makeRequest_product_sales() {
    Rails.ajax({
      type: "patch",
      url: `/products/update_best_sales/${this.id}?best_sale=${this.display}`
    })
  }
  makeRequest_product_nouveaute() {
    Rails.ajax({
      type: "patch",
      url: `/products/update_nouveaute/${this.id}?nouveaute=${this.display}`
    })
  }
  makeRequest_order_payement() {
    Rails.ajax({
      type: "patch",
      url: `/order_internes/update_order_interne/${this.id}?payement=${this.display}`
    })
  }
  makeRequest_order_shipping() {
    Rails.ajax({
      type: "patch",
      url: `/orders/update_order/${this.id}?shipping=${this.display}`
    })
  }
  makeRequest_order_delivery() {
    Rails.ajax({
      type: "patch",
      url: `/orders/update_order_delivery_method/${this.id}?courrier_id=${this.display}&courrier_name=${this.courrier}&price=${this.pricedelivery}`
    })
  }
  makeRequest_oeuvre_localisation() {
    Rails.ajax({
      type: "patch",
      url: `/oeuvres/update_localisation/${this.id}?display_choice=${this.display}`
    })
  }

  makeRequest_artiste_builder() {
    Rails.ajax({
      type: "patch",
      url: `/artistes/update_builder/${this.id}?display_choice=${this.display}`
    })
  }

  makeRequest_exposition_product() {
    Rails.ajax({
      type: "patch",
      url: `/expositions/update_product/${this.id}?display_product=${this.display}`
    })
  }






}
