import { Controller } from "stimulus"
// import Flatpickr
import Flatpickr from "stimulus-flatpickr";
import { French } from 'flatpickr/dist/l10n/fr';

// creates a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {

  connect() {
    //define locale and global flatpickr settings for all datepickers
    this.config = {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      locale: French
    };
    super.connect();
  }

}
