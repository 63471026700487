import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "form" ]
  connect() {
  }

  submit() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(this.formTarget, 'submit')
    }, 1000)
  }
}
