// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs"
export default class extends Controller {
  static targets = [ "email", "artiste", "oeuvre", "card" ]

  connect() {
  }

  load() {
        this.oeuvre= this.data.get("oeuvre");
        this.email= this.data.get("email");
        this.cardTarget.classList.toggle("border-blue");
        this.makeRequest();
    }
  addAll()
  {
    this.email= this.data.get("email");
    this.artiste= this.data.get("artiste");
    $('#selectAll').attr('data-action','click->oeuvre-create#removeAll');
    $('#selectAll').attr('data-front','SUPPRIMER TOUT');
    $('#selectAll').attr('data-back','SUPPRIMER TOUT');
    if ($('.card').hasClass('border-blue'))
    {}
    else
    {
      $('.card').addClass('border-blue');
    }
    this.makeRequestaddAll();
  }
  removeAll()
  {
    this.email= this.data.get("email");
    this.artiste= this.data.get("artiste");
    $('#selectAll').attr('data-action','click->oeuvre-create#addAll');
    $('#selectAll').attr('data-front','AJOUTER TOUT');
    $('#selectAll').attr('data-back','AJOUTER TOUT');
    if ($('.card').hasClass('border-blue'))
    {
      $('.card').removeClass('border-blue');
    }
    else
    {
      $('.card').addClass('border-blue');
    }
    this.makeRequestremoveAll();
  }

  makeRequest() {
    Rails.ajax({
      type: "post",
      url: `/emailoeuvrecreate?oeuvre_id=${this.oeuvre}&email_id=${this.email}`,
    })
  }

  makeRequestaddAll() {
    Rails.ajax({
      type: "post",
      url: `/emailoeuvrecreate?artiste_id=${this.artiste}&email_id=${this.email}&function=addAll`,
    })
  }

  makeRequestremoveAll() {
    Rails.ajax({
      type: "post",
      url: `/emailoeuvrecreate?artiste_id=${this.artiste}&email_id=${this.email}&function=removeAll`,
    })
  }

}
