import { Controller } from "stimulus"

export default class extends Controller {
static targets = [ "artiste" ]

connect() {

  }

hover_in() {
        this.image_src == null;
        this.artiste = this.artisteTarget.innerText;
        this.image_src = document.getElementById(`image_url_${this.artiste}`).getAttribute('value');
        $("#image_artiste_oeuvre").empty();
        $("#image_artiste_oeuvre").html(`<div><img id="oeuvre" src="${this.image_src}" style="object-fit:contain;max-width:90%;min-height:65%;width:auto;height:auto;max-height:480px"/></div>`)
        $(`#artiste_${this.artiste}`).css("color","#000");
    }



hover_out() {
    $(`#artiste_${this.artiste}`).css("color","#000");
  }


  hover_in2() {
              this.artiste = this.artisteTarget.innerText;
              $(`.artiste_${this.artiste}`).css("color","#000");
          }


hover_in_title() {
              this.artiste = this.artisteTarget.innerText;
              $(`.titre_${this.artiste}`).css("font-weight","500");

          }
hover_out_title() {
      $(`.titre_${this.artiste}`).css("font-weight","200");
  }


hover_publi()
{
          this.artiste = this.artisteTarget.innerText;
          $(`#image_1_${this.artiste}`).css("display","none");
          $(`#image_2_${this.artiste}`).css("display","block");


      }
hover_publi_out()
{
  this.artiste = this.artisteTarget.innerText;
  $(`#image_2_${this.artiste}`).css("display","none");
  $(`#image_1_${this.artiste}`).css("display","block");

}




}
