$(function(){
    $('#selector_encadrement').change(function() {
        if(this.checked){
            $("#oeuvre_oeuvre_cartels_attributes_0_dimension_encadrement_check").val("checked");
            $('#oeuvre_oeuvre_cartels_attributes_0_dimension_encadrement').prop("disabled", false);
        } else {
            $("#oeuvre_oeuvre_cartels_attributes_0_dimension_encadrement_check").val("unchecked");
            $('#oeuvre_oeuvre_cartels_attributes_0_dimension_encadrement').prop("disabled", true);
        }
    });
});

$(function(){
    $('#selector_caisse_transport').change(function() {
        if(this.checked){
            $("#oeuvre_oeuvre_cartels_attributes_0_caisse_transport_check").val("checked");
            $('#oeuvre_oeuvre_cartels_attributes_0_caisse_transport').prop("disabled", false);
        } else {
            $("#oeuvre_oeuvre_cartels_attributes_0_caisse_transport_check").val("unchecked");
            $('#oeuvre_oeuvre_cartels_attributes_0_caisse_transport').prop("disabled", true);
        }
    });
});

$(function(){
    $('#artiste_consignataire').change(function() {
        if(this.checked){
            $('#oeuvre_oeuvre_sales_infos_attributes_0_consignataire').val($('#artiste_name_check').text());
        } else {
            $('#oeuvre_oeuvre_sales_infos_attributes_0_consignataire').val('');

        }
    });
});
