import { Controller } from "stimulus"
require("packs/parallax")


export default class extends Controller {
  static targets = ["entries", "pagination","pipedata"]

  connect(){
    console.log("pagination")
  }

  scroll() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { return }

    let url = next_page.href

    var body = document.body,
      html = document.documentElement

    var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

    if (window.pageYOffset >= height - window.innerHeight - 200) {
      this.loadMore(url)

    }
  }

  scroll_oeuvre() {

    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { return window.dispatchEvent(new Event('resize'));}
    console.log(next_page)
    let url = next_page.href


      if ($(window).scrollTop() >= $("#oeuvre_pagy").offset().top - 100) {
        this.loadMore(url)
        window.dispatchEvent(new Event('resize'));
      }



  }



  scroll_pipe() {
    console.log("Connected")
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { return }
    let url = next_page.href
    let pipe = this.data.get('pipedata');
    if ($(`#${pipe}`).scrollTop() >= $("#pipeline_container").height() - 500) {
        this.loadMore_pipe(url, pipe)
    }

  }


  loadMore(url) {
    if (this.loading) { return }
    this.loading = true

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
        this.paginationTarget.innerHTML = data.pagination
        this.loading = false
      }
    })

  }


  loadMore_pipe(url, pipe) {
    if (this.loading) { return }
    this.loading = true

    Rails.ajax({
      type: 'GET',
      url: url + `&pipe=${pipe}`,
      dataType: 'json',
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
        this.paginationTarget.innerHTML = data.pagination
        this.loading = false
      }
    })

  }

}
