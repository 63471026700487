import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "formid" ]

  connect() {
console.log("form")
  }

  submit() {
    this.formTarget= this.data.get("formid");
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(document.querySelector(`#form_1_${this.formTarget}`), 'submit')
    }, 2000)
  }
}
