import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import $ from 'jquery';

export default class extends Controller {
static targets = [ "category", "artiste" ]


  connect() {

  }

  add() {

    var pathname = null;
    var urlParams = null;
    var newUrl = null;
    var artiste_id = null;
    var pathname = $("#search_filters_url").attr('href');
    var urlParams = new URLSearchParams(pathname);
    var artistes_section = urlParams.get('filters');
    var artiste_id = $(this.artisteTarget).data('id');
     if (artistes_section == "")
     {
       var artiste_array = [];
     }
     else
     {
       var artiste_array = artistes_section.split(",");
     }

     if (artiste_array.includes(String(artiste_id)))
       {
           var url = new URL(pathname);
           artiste_array.splice(artiste_array.indexOf(artiste_id), 1);
           url.searchParams.set("filters", oeuvre_array); // setting your param
           var newUrl = url.href;
           $("#search_filters_url").attr("href", newUrl);
           $(this.artisteTarget).css("font-weight","500");

        }
        else
        {
          $(this.artisteTarget).css("font-weight","bold");

          var url = new URL(pathname);
          artiste_array.push(artiste_id);
          url.searchParams.set("filters", artiste_array); // setting your param
          var newUrl = url.href;
          $("#search_filters_url").attr("href", newUrl);
        }

    }
}
