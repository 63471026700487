// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require bootstrap
//= require country_state_select

window.Rails = require("@rails/ujs")
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "controllers"
import "@hotwired/turbo-rails"
import noUiSlider from "nouislider"
import AOS from 'aos';

Rails.start()
Turbolinks.start()
ActiveStorage.start()


require("packs/smooth_scroll_page")
require("trix")
require("@rails/actiontext")
require("packs/checked")
require("packs/toggle_form")
require("packs/navbar")

//require("packs/icons")
//require("packs/theme")
require("packs/parallax")
require("packs/add-eu-banner")
require("packs/best_in_place")

$(document).ready(function() {
  jQuery(".best_in_place").best_in_place();
});


window.addEventListener('load', function() { AOS.init();AOS.refresh() });

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
