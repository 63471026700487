
import { Controller } from 'stimulus';
import Carousel from "stimulus-carousel"
export default class extends Controller {
static targets = [ "slide" ]

connect() {
    var swiper = new Swiper('.swiper-container', {
          effect : "fade",
          spaceBetween: 20,
          speed: 500,
          clickable: true,
          preloadImages: false,
          lazy: {
              loadPrevNext: true,
              loadPrevNextAmount: 1,
              loadOnTransitionStart: true,
          },
          fadeEffect: { crossFade: true },
          navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",}
    });
    let searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('oeuvre'))
    {
      let param = searchParams.get('oeuvre')
      var iNum = parseInt(param) - 2;
      swiper.slideTo(0,100)
    }
  }
slide1()
{
  var swiper= document.querySelector('.swiper-container').swiper;
  swiper.slideTo(0,1000)
}
slide2()
{
  var swiper= document.querySelector('.swiper-container').swiper;
  swiper.slideTo(1,1000)
}

slideX()
{
  this.slide == null;
  this.slide = this.slideTarget.innerText;
  var iNum = parseInt(this.slide);
  var swiper= document.querySelector('.swiper-container').swiper;
  if($("#wall_image").length){
    swiper.slideTo(iNum,1000)
  }
  else
  {
    swiper.slideTo(iNum -1,1000)
  }
}


}
