$(function(){
    $('#biographie_switch').change(function() {
        if(this.checked){
            $('#biographie_label').text('Biographie fr');
            $('#biographie_fr').show();
            $('#biographie_en').hide();
        }
        else
        {
            $('#biographie_label').text('Biographie en');
            $('#biographie_fr').hide();
            $('#biographie_en').show();
        }
    });
});


$(function(){
    $('#citation_switch').change(function() {
        if(this.checked){
            $('#citation_label').text('citation fr');
            $('#citation_fr').show();
            $('#citation_en').hide();
        }
        else
        {
            $('#citation_label').text('citation en');
            $('#citation_fr').hide();
            $('#citation_en').show();
        }
    });
});

$(function(){
    $('#factsheet_switch').change(function() {
        if(this.checked){
            $('#factsheet_label').text('Texte Factsheet FR');
            $('#biographie_factsheet').show();
            $('#biographie_factsheet_en').hide();
        }
        else
        {
            $('#factsheet_label').text('Texte Factsheet EN');
            $('#biographie_factsheet').hide();
            $('#biographie_factsheet_en').show();
        }
    });
});


$(function(){
    $('#signature_switch').change(function() {
        if(this.checked){
            $('#signature_label').text('signature fr');
            $('#signature_fr').show();
            $('#signature_en').hide();
        }
        else
        {
            $('#signature_label').text('signature en');
            $('#signature_fr').hide();
            $('#signature_en').show();
        }
    });
});

$(function(){
    $('#provenance_switch').change(function() {
        if(this.checked){
            $('#provenance_label').text('provenance fr');
            $('#provenance_fr').show();
            $('#provenance_en').hide();
        }
        else
        {
            $('#provenance_label').text('provenance en');
            $('#provenance_fr').hide();
            $('#provenance_en').show();
        }
    });
});

$(function(){
    $('#bibliographie_switch').change(function() {
        if(this.checked){
            $('#bibliographie_label').text('bibliographie fr');
            $('#bibliographie_fr').show();
            $('#bibliographie_en').hide();
        }
        else
        {
            $('#bibliographie_label').text('bibliographie en');
            $('#bibliographie_fr').hide();
            $('#bibliographie_en').show();
        }
    });
});

$(function(){
    $('#notes_switch').change(function() {
        if(this.checked){
            $('#notes_label').text('notes fr');
            $('#notes_fr').show();
            $('#notes_en').hide();
        }
        else
        {
            $('#notes_label').text('notes en');
            $('#notes_fr').hide();
            $('#notes_en').show();
        }
    });
});

$(function(){
    $('#exposition_switch').change(function() {
        if(this.checked){
            $('#exposition_label').text('exposition fr');
            $('#exposition_fr').show();
            $('#exposition_en').hide();
        }
        else
        {
            $('#exposition_label').text('exposition en');
            $('#exposition_fr').hide();
            $('#exposition_en').show();
        }
    });
});


$(function(){
    $('#exposition_switch').change(function() {
        if(this.checked){
            $('#exposition_text').text('Texte exposition fr');
            $('#expo_fr').show();
            $('#expo_en').hide();
        }
        else
        {
            $('#exposition_text').text('Texte exposition en');
            $('#expo_fr').hide();
            $('#expo_en').show();
        }
    });
});

$(function(){
    $('#exposition_switch_citation').change(function() {
        if(this.checked){
            $('#exposition_citation').text('Citation fr');
            $('#expo_fr_citation').show();
            $('#expo_en_citation').hide();
        }
        else
        {
            $('#exposition_citation').text('Citation en');
            $('#expo_fr_citation').hide();
            $('#expo_en_citation').show();
        }
    });
});


$(function(){
    $('#localisation_switch').change(function() {
        if(this.checked){
            $('#localisation_label').text('Localisation (liste)');
            $('#localisation_select').show();
            $('#localisation_select').attr('required', 'required');
            $('#localisation_input').hide();
            $('#localisation_input').removeAttr('required');
            $('#exposition_localisation').val('');
        }
        else
        {
            $('#localisation_label').text('Localisation(manuelle)');
            $('#localisation_select').hide();
            $('#localisation_select').removeAttr('required');
            $('#localisation_input').show();
            $('#localisation_input').attr('required', 'required');
        }
    });
});


$(function(){
    $('#medium_switch').change(function() {
        if(this.checked){
            $('#medium_label').text('FORMAT FR');
            $('#medium_fr').show();
            $('#medium_en').hide();
        }
        else
        {
            $('#medium_label').text('FORMAT EN');
            $('#medium_fr').hide();
            $('#medium_en').show();
        }
    });
});


$(function(){
    $('#description_switch').change(function() {
        if(this.checked){
            $('#description_label').text('DESCRIPTION FR');
            $('#description_fr').show();
            $('#description_en').hide();
        }
        else
        {
            $('#description_label').text('DESCRIPTION EN');
            $('#description_fr').hide();
            $('#description_en').show();
        }
    });
});
