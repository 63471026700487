import { Controller } from "stimulus"

export default class extends Controller {
static targets = [ "expo" ]

connect() {

  }

hover_in() {
        this.expo = this.expoTarget.innerText;
        $( `#hidder_${this.expo}` ).css( "opacity", 0 );
        $(`#hover_${this.expo}`).addClass('hover');
        $(`.texte_grey_${this.expo}`).css("color", "#404040");
        $(`#line_${this.expo}`).css("border-bottom", "solid 1px #404040");
    }
hover_out() {
        $( `#hidder_${this.expo}` ).css( "opacity", 0.6 );
        $(`#hover_${this.expo}`).removeClass('hover');
        $(`.texte_grey_${this.expo}`).css("color", "white");
        $(`#line_${this.expo}`).css("border-bottom", "solid 1px white");
  }
}
