import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import Sortable from "sortablejs"

export default class extends Controller {
static targets = [ 'status', 'id', 'template', 'expo' ]
connect() {
    this.sortable = Sortable.create(this.element, {
    group:"list",
    onEnd: this.end.bind(this)
  })
}

end(event) {
  let id = event.item.dataset.id
  let status = event.to.dataset.id
  let template = event.to.dataset.template
  let expo = $("#exposition_id").text();
  Rails.ajax({
    url:`/expositions/update_position/${id}?position=${status}&template=${template}&expo=${expo}`,
    type:'PATCH'
  })
  }
}
