import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import $ from 'jquery';

export default class extends Controller {
static targets = [ "oeuvre", "artiste", "button" ]


  connect() {

  }

  add() {
    var pathname_en = null;
    var pathname_fr = null;
    var mandat_en = null;
    var mandat_fr = null;
    var etiquette_cadre = null;
    var etiquette_chassis = null;
    var etiquette_oeuvre = null;

    var urlParams =null;
    var newUrl = null;
    var oeuvre_id = null;


    var pathname_en = $("#factsheet_en").attr('href');
    var pathname_fr = $("#factsheet_fr").attr('href');

    var mandat_en = $("#mandat_en").attr('href');
    var mandat_fr = $("#mandat_fr").attr('href');

    var etiquette_cadre = $("#etiquette_cadre").attr('href');
    var etiquette_chassis = $("#etiquette_chassis").attr('href');
    var etiquette_oeuvre = $("#etiquette_oeuvre").attr('href');



    var oeuvre_id = $(this.oeuvreTarget).data('id');



     var urlParams = new URLSearchParams(pathname_fr);
     var oeuvre = urlParams.get('oeuvres');
     var language = urlParams.get('language');


     if (oeuvre == "")
     {
       var oeuvre_array = [];
     }
     else
     {
       var oeuvre_array = oeuvre.split(",");
     }



         if (oeuvre_array.includes(String(oeuvre_id)))
       {
           var url_fr = new URL(pathname_fr);
           var url_en = new URL(pathname_en);
           var url_mandat_fr = new URL(mandat_en);
           var url_mandat_en = new URL(mandat_fr);
           var url_etiquette_cadre = new URL(etiquette_cadre);
           var url_etiquette_chassis = new URL(etiquette_chassis);
           var url_etiquette_oeuvre = new URL(etiquette_oeuvre);


           oeuvre_array.splice(oeuvre_array.indexOf(oeuvre_id), 1);
           url_fr.searchParams.set("oeuvres", oeuvre_array); // setting your param
           url_en.searchParams.set("oeuvres", oeuvre_array);
           url_mandat_fr.searchParams.set("oeuvres", oeuvre_array); // setting your param
           url_mandat_en.searchParams.set("oeuvres", oeuvre_array);

           url_etiquette_cadre.searchParams.set("oeuvres", oeuvre_array);
           url_etiquette_chassis.searchParams.set("oeuvres", oeuvre_array);
           url_etiquette_oeuvre.searchParams.set("oeuvres", oeuvre_array);

           var newUrl_fr = url_fr.href;
           var newUrl_en = url_en.href;
           var newUrl_mandat_fr = url_mandat_fr.href;
           var newUrl_mandat_en = url_mandat_en.href;

           var newUrl_etiquette_cadre = url_etiquette_cadre.href;
           var newUrl_etiquette_chassis = url_etiquette_chassis.href;
           var newUrl_etiquette_oeuvre = url_etiquette_oeuvre.href;


           $("#factsheet_fr").attr("href", newUrl_fr);
           $("#factsheet_en").attr("href", newUrl_en);
           $("#mandat_fr").attr("href", newUrl_mandat_fr);
           $("#mandat_en").attr("href", newUrl_mandat_en);
           $("#etiquette_cadre").attr('href', newUrl_etiquette_cadre);
           $("#etiquette_chassis").attr('href', newUrl_etiquette_chassis);
           $("#etiquette_oeuvre").attr('href', newUrl_etiquette_oeuvre);
          }
        else
        {
          var url_fr = new URL(pathname_fr);
          var url_en = new URL(pathname_en);
          var url_mandat_fr = new URL(mandat_en);
          var url_mandat_en = new URL(mandat_fr);

          var url_etiquette_cadre = new URL(etiquette_cadre);
          var url_etiquette_chassis = new URL(etiquette_chassis);
          var url_etiquette_oeuvre = new URL(etiquette_oeuvre);

          oeuvre_array.push(oeuvre_id);
          url_fr.searchParams.set("oeuvres", oeuvre_array); // setting your param
          url_en.searchParams.set("oeuvres", oeuvre_array);
          url_mandat_fr.searchParams.set("oeuvres", oeuvre_array); // setting your param
          url_mandat_en.searchParams.set("oeuvres", oeuvre_array);
          url_etiquette_cadre.searchParams.set("oeuvres", oeuvre_array);
          url_etiquette_chassis.searchParams.set("oeuvres", oeuvre_array);
          url_etiquette_oeuvre.searchParams.set("oeuvres", oeuvre_array);

          var newUrl_fr = url_fr.href;
          var newUrl_en = url_en.href;
          var newUrl_mandat_fr = url_mandat_fr.href;
          var newUrl_mandat_en = url_mandat_en.href;
          var newUrl_etiquette_cadre = url_etiquette_cadre.href;
          var newUrl_etiquette_chassis = url_etiquette_chassis.href;
          var newUrl_etiquette_oeuvre = url_etiquette_oeuvre.href;

          $("#factsheet_en").attr("href", newUrl_en);
          $("#factsheet_fr").attr("href", newUrl_fr);
          $("#mandat_fr").attr("href", newUrl_mandat_fr);
          $("#mandat_en").attr("href", newUrl_mandat_en);
          $("#etiquette_cadre").attr('href', newUrl_etiquette_cadre);
          $("#etiquette_chassis").attr('href', newUrl_etiquette_chassis);
          $("#etiquette_oeuvre").attr('href', newUrl_etiquette_oeuvre);

        }



    this.oeuvreTarget.classList.toggle("border-blue");
    this.buttonTarget.classList.toggle("selected_icon");


    }


    price() {
        var pathname_en = null;
        var pathname_fr = null;
        var urlParams =null;
        var newUrl = null;
        var price_url = null;

        var pathname_en = $("#factsheet_en").attr('href');
        var pathname_fr = $("#factsheet_fr").attr('href');

         var urlParams = new URLSearchParams(pathname_fr);
         var language = urlParams.get('language');
         var price_url = urlParams.get('price');


         if ($('#switch_fachsheet').is(":checked"))
         {
           var price = "YES";
           var url_fr = new URL(pathname_fr);
           var url_en = new URL(pathname_en);
           url_fr.searchParams.set("price", price); // setting your param
           url_en.searchParams.set("price", price);
           var newUrl_fr = url_fr.href;
           var newUrl_en = url_en.href;
           $("#factsheet_fr").attr("href", newUrl_fr);
           $("#factsheet_en").attr("href", newUrl_en);
         }
         else
         {
           var price = "NO";
           var url_fr = new URL(pathname_fr);
           var url_en = new URL(pathname_en);
           url_fr.searchParams.set("price", price); // setting your param
           url_en.searchParams.set("price", price);
           var newUrl_fr = url_fr.href;
           var newUrl_en = url_en.href;
           $("#factsheet_fr").attr("href", newUrl_fr);
           $("#factsheet_en").attr("href", newUrl_en);
         }

        }
}
