import { Controller } from "stimulus"
import Rails from "@rails/ujs"
export default class extends Controller {
  static targets = [ "card", "status", "url" ]

  connect(){

    }

  update() {
        this.id= this.data.get("id");
        this.status = this.data.get("status");
        this.url = this.data.get("url");
        this.cardTarget.classList.toggle("border-blue");
        if (this.url == "/publications/hightlight")
        {
        this.makeRequest_publication_highlights();
        }
        else if (this.url == "/news/hightlight")
        {
          this.makeRequest_news_highlights();
        }
    }
  makeRequest_publication_highlights() {
    Rails.ajax({
      type: "patch",
      url: `/publications/hightlight/${this.id}?display_choice=${this.status}`
    })
  }

  makeRequest_news_highlights() {
    Rails.ajax({
      type: "patch",
      url: `/news/hightlight/${this.id}?display_choice=${this.status}`
    })
  }

}
