import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import Sortable from "sortablejs"

export default class extends Controller {
static targets = [ 'image_id', 'expoemail_id', 'expo', 'id' ]
connect() {
    this.sortable = Sortable.create(this.element, {
    group:"list",
    onEnd: this.end.bind(this)
  })
}

end(event) {
  let image = event.item.dataset.id
  let template = event.to.dataset.template
  let expoemail_id = event.to.dataset.expoemail_id
  let text_expo = event.to.dataset.textexpo
  let position = event.to.dataset.position
  Rails.ajax({
    url:`/expositionemails/update_image/${expoemail_id}?image_id=${image}&template=${template}&textexpo=${text_expo}&position=${position}`,
    type:'POST'
  })
  }
}
